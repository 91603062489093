import MoreVertIcon from "@material-ui/icons/MoreVert";
import moment from "moment";

import { Button, Pill, PopOverList, PopOverMenu, Text } from "components/commons";

import { AccountStatus, DateTime, Path, PLBTypes, Role } from "enums";
import locale from "localization";
import {
  formatAmount,
  formatVolume,
  prettifyAccountStatus,
  prettifyFleetAccountType,
  formatNumber,
  prettifyPlbType,
} from "utils";

import styles from "./account-list.module.scss";

export const mapDataToList = ({ fleets, history, handleUpdateStatus, role }) => {
  const {
    businessName,
    shortName,
    businessNature,
    station,
    fleetStations,
    wallet,
    literBalance,
    inventoryLimit,
    createdAt,
    status,
    fleetId,
    accountType,
    updatedAt,
    plbType,
    points,
  } = fleets;

  const fleetStationsCount = fleetStations?.length;

  const accountDate = {
    active: moment(updatedAt).format(DateTime.G),
    deactivated: moment(updatedAt).format(DateTime.G),
    pending: "--",
  };

  const options = [
    {
      content: locale.viewAccountDetails,
      onClick: () => {
        history.push(Path.ViewFleetDetailsById(fleetId, plbType));
      },
    },
    {
      content: locale.viewPricingDetails,
      onClick: () => {
        history.push(Path.ViewFleetPricingById(fleetId));
      },
      disabled:
        plbType === PLBTypes.PLB_LOCQPAY ||
        plbType === PLBTypes.PLB_LOCQPAY_MANUAL_PO ||
        plbType === PLBTypes.PLB_LOCQPAY_MANUAL_PO_CREDIT,
    },
    {
      removable: true,
      content: locale.deactivate,
      disabled:
        status === AccountStatus.Deactivated ||
        status === AccountStatus.Expired ||
        role === Role.Accounting,
      onClick: () => {
        handleUpdateStatus(
          {
            fleetId,
            status: AccountStatus.Deactivated,
          },
          businessName
        );
      },
    },
    {
      removable: true,
      content: locale.reactivate,
      disabled:
        status === AccountStatus.Active ||
        status === AccountStatus.Pending ||
        status === AccountStatus.Expired ||
        status === AccountStatus.Inactive,
      onClick: () => {
        handleUpdateStatus(
          {
            fleetId,
            status: AccountStatus.Active,
          },
          businessName
        );
      },
    },
  ];

  const isPlbTypeLocqPayAndManual = [PLBTypes.PLB_LOCQPAY, PLBTypes.PLB_LOCQPAY_MANUAL_PO].includes(
    plbType
  );

  const renderReferenceStation = (stationName) => (isPlbTypeLocqPayAndManual ? "-" : stationName);

  return {
    businessNameId: (
      <>
        <Text>{businessName}</Text>
        <Text className={styles.subBusinessId}>{shortName}</Text>
      </>
    ),
    businessNature,
    accountType: <Text>{prettifyFleetAccountType(accountType)}</Text>,
    plbType: <Text>{prettifyPlbType(plbType)}</Text>,
    referenceStation: renderReferenceStation(station?.name),
    fleetStations:
      fleetStationsCount > 0 ? (
        <PopOverList
          list={fleetStations.map((fleet) => {
            return fleet.name;
          })}
        >
          <Button link>{fleetStationsCount}</Button>
        </PopOverList>
      ) : (
        <p className={styles.italic}>{locale.allStations}</p>
      ),
    pesoBalance: formatAmount(wallet?.walletBalance),
    literBalance: plbType !== PLBTypes.PLB_HEDGING ? formatVolume(0) : formatVolume(literBalance),
    vipPointsBalance: points ? formatNumber(points, 2) : "-",
    inventoryLimit: formatNumber(inventoryLimit, 2),
    dateOnboarded: moment(createdAt).format(DateTime.G),
    statusDate: (
      <>
        <Pill
          grass={status === AccountStatus.Active}
          cheddar={status === AccountStatus.Pending}
          deepRed={status === AccountStatus.Deactivated}
          cement={status === AccountStatus.Expired}
          desertstorm={status === AccountStatus.Inactive}
        >
          {prettifyAccountStatus(status)}
        </Pill>
        <Text className={styles.subBusinessId}>{accountDate[`${status}`]}</Text>
      </>
    ),
    actions: (
      <PopOverMenu options={options}>
        <MoreVertIcon className={styles.icon} />
      </PopOverMenu>
    ),
  };
};

export const mapFilterToRequest = (filterState) => {
  const { status, accountType, ...fs } = filterState;
  const newStatus = status !== "all" ? status : "";
  const newAccountType = accountType !== "all" ? accountType : "";

  return {
    ...fs,
    status: newStatus,
    accountType: newAccountType,
  };
};
